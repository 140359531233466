var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jeopardai-team-results-text" }, [
      _c("div", { staticClass: "jeopardai-team-results-text__title" }, [
        _vm._v("CHALLENGES"),
      ]),
      _c("i", {
        staticClass: "mdi mdi-sword-cross jeopardai-team-results-text__icon",
      }),
      _c("div", { staticClass: "jeopardai-team-results-text__subtitle" }, [
        _vm._v(" DID YOU BEAT THE MACHINE? "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }